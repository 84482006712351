import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { SnackbarProvider, SnackbarKey } from 'notistack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GlobalStyles } from '@mui/material'
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material'
import { BrowserRouter } from 'react-router-dom'
import useRoute from '../hooks/useRoute.hooks'
import useSnackbar from '../hooks/useSnackbar.hooks'

import Constants from '../constants'
import Snackbar, { SnackbarClose } from './layout/Snackbar.layout'
import i18n from '../utils/i18n.utils'
import { sessionQuery } from '../store/session'
import { sendError } from '../api/base.api'
import { Mode, Route } from '../models/commons.models'
interface AppProps {
  children: JSX.Element
}

type Props = { children: any; redirect: any }
type State = { hasError: boolean }
class GlobalError extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // initialize the error state
    this.state = { hasError: false }
  }

  // if an error happened, set the state to true
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      // set time out to get real location in error
      setTimeout(() => {
        this.props.redirect()
        this.setState({
          hasError: false,
        })
      }, 100)
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    sendError({
      message: error?.message,
      stack: error?.stack,
      errorInfo,
      href: window.location.href,
      user: sessionQuery.getUserId(),
      mode: Constants.mode,
      platform: Constants.platform,
    })
  }

  render() {
    // if error happened, return a fallback component
    if (this.state.hasError) {
      return <></>
    }

    return this.props.children
  }
}
const withRouter = (WrappedComponent: any) => (props: any) => {
  const { goTo } = useRoute()
  const show = useSnackbar()

  return (
    <WrappedComponent
      {...props}
      redirect={() => {
        show(new Error('error sent to api') as any)
        goTo({
          route:
            Constants.mode === Mode.front || Constants.mode === Mode.storeFront
              ? Route.home
              : Route.workspace,
        })
      }}
    />
  )
}
const GlobalErrorWithRouter = withRouter(GlobalError)

const App: React.FC<AppProps> = ({ children }): JSX.Element => {
  return (
    <ThemeProvider theme={Constants.getTheme()}>
      <CssBaseline />
      <GlobalStyles styles={Constants.globalCss} />

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4000}
          iconVariant={{
            success: <CheckCircleIcon />,
            warning: <WarningIcon />,
            error: <ErrorIcon />,
            info: <InfoIcon />,
          }}
          Components={{
            success: Snackbar,
            warning: Snackbar,
            error: Snackbar,
            info: Snackbar,
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={(snackbarKey: SnackbarKey) => <SnackbarClose snackbarKey={snackbarKey} />}>
          <BrowserRouter>
            <GlobalErrorWithRouter>{children}</GlobalErrorWithRouter>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
