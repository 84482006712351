import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, styled, Typography, MenuItem, InputLabel } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { sessionService } from '../../store/session'
import { organizationsService } from '../../store/organizations'
import { requestsService } from '../../store/requests'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { Profession } from '../../models/users.models'
import { SignupInterface } from '../../models/sessions.models'
import { FormItem, ItemType } from '../../models/props.models'
import { ManageOrganization, Organization } from '../../models/organizations.models'
import { useSearchParams } from 'react-router-dom'

import CGUContent from './cgu.session'
import RGPDContent from './rgpd.session'
import Form from '../common/Form.common'
import ModalForm from '../common/ModalForm.common'
import ModalFormOrganization from '../organization/ModalForm.organization'
import Modal from '../layout/Modal.layout'
import Tooltip from '../common/Tooltip.common'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { logIfDev } from '../../utils/commons.utils'

const SuccessTitle = styled(Typography)({ lineHeight: 1.25, textAlign: 'center' })
const SuccessDescription = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.25,
  textAlign: 'center',
})
const NoResultItem = styled(MenuItem)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  padding: '24px 0',
  maxWidth: '100%',
  wordBreak: 'break-all',
  '&[aria-label=with-border]': {
    borderTop: `1px solid ${theme.palette.menuBorder}`,
  },
  '& > .MuiTypography-root:first-of-type': {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  '& > .MuiTypography-root:nth-of-type(2)': {
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: 500,
  },
}))

type FormSuccessProps = {
  onSuccess?: () => void
  onClose?: () => void
}
const FormSignup: React.FC<FormSuccessProps> = ({ onSuccess, onClose }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<SignupInterface>({} as SignupInterface)
  const setOrganization = (organization: ManageOrganization) => {
    setValue((val: SignupInterface) => ({ ...val, organization }))
    setRequestOrganization(undefined)
  }
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [requestOrganization, setRequestOrganization] = useState<string>()
  const [modal, setModal] = useState<'' | 'addOrganization' | 'rgpd' | 'cgu'>('')
  const phoneNumber = useMemo(
    () =>
      sessionService.getCountryParam('phoneNumber') as
        | { format: string; validation: string }
        | undefined,
    [],
  )

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const requestId = searchParams.get('request')
    if (requestId) {
      const getRequest = async () => {
        setLoading(true)
        try {
          const { customReceiver } = await requestsService.getRequestById(requestId)
          setValue((val: any) => ({
            ...val,
            request: requestId,
            firstname: customReceiver?.firstname,
            lastname: customReceiver?.lastname,
            email: customReceiver?.email,
          }))

          if (customReceiver?.organizationId) {
            const organization = await organizationsService.getOrganizationById(
              customReceiver?.organizationId,
            )
            if (organization) {
              setOrganization(organization)
            }
          } else {
            setRequestOrganization(customReceiver?.organization)
          }
        } catch (err) {}
        setLoading(false)
      }
      getRequest()
    }
  }, [searchParams])

  const informationsItems: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'lastname',
        required: true,
        props: {
          label: t('users:attributes.lastname'),
          placeholder: t('users:attributes.lastname'),
          autocomplete: 'family-name',
        },
      },
      {
        type: ItemType.text,
        key: 'firstname',
        required: true,
        props: {
          label: t('users:attributes.firstname'),
          placeholder: t('users:attributes.firstname'),
          autocomplete: 'given-name',
        },
      },
      {
        type: ItemType.text,
        key: 'email',
        required: true,
        rules: [YupUtils.FieldValidationType.email],
        props: {
          label: t('users:attributes.email'),
          placeholder: t('users:attributes.email'),
          type: 'email',
          autocomplete: 'email',
        },
      },
      {
        type: ItemType.text,
        key: 'phoneNumber',
        rules: [
          phoneNumber
            ? { rule: YupUtils.FieldValidationType.regexp, params: phoneNumber.validation }
            : () => '',
        ],
        props: {
          pattern: phoneNumber?.format,
          label: t('users:attributes.phoneNumber'),
          placeholder: t('users:attributes.phoneNumberFormat'),
          autocomplete: 'tel',
        },
      },
      {
        type: ItemType.radio,
        key: 'professions',
        required: true,
        props: {
          label: t('users:attributes.professions'),
          items: createOptionsFromEnum(Profession, 'users:professions'),
          multiple: true,
          split: true,
        },
      },
    ],
    [t, phoneNumber],
  )

  const organizationItems: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.asyncSelect,
        key: 'organization',
        formatValue: (organization: Organization) => {
          if (requestOrganization) {
            return { label: requestOrganization, value: requestOrganization }
          }
          return organization
            ? { label: organization.name ?? '', value: organization._id ?? '' }
            : undefined
        },
        required: true,
        onChange: (orga: any) => {
          if (!orga && requestOrganization) {
            setRequestOrganization(undefined)
          }
          setOrganization(orga)
        },
        props: {
          label: t('organizations:attributes.name'),
          placeholder: t('organizations:attributes.namePlaceholder'),
          minimumSearchLength: 3,
          getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
          dataValue: true,
          noResultComponent: (
            <NoResultItem onClick={() => setModal('addOrganization')}>
              <Typography sx={{ textAlign: 'center' }}>
                {t('sessions:components.formSignup.organization.noResult')}
              </Typography>
              <Typography>{t('sessions:components.formSignup.organization.create')}</Typography>
            </NoResultItem>
          ),
        },
      },
      (signup: SignupInterface) => ({
        type: ItemType.info,
        key: 'info',
        hideItem: !signup.organization,
        props: {
          children: [
            {
              label: t('organizations:attributes.legalForm'),
              value: signup.organization?.legalForm
                ? t(`organizations:legalForms.${signup.organization?.legalForm}`)
                : '-',
            },
            { label: t('organizations:attributes.siret'), value: signup.organization?.siret },
            {
              label: t('organizations:attributes.location'),
              value: signup.organization?.location?.fullAddress?.replace(',', '\n'),
            },
          ]
            .filter((r) => !!r.value)
            .map((r) => (
              <Box key={r.label}>
                <Typography>{r.label}</Typography>
                <Typography variant="body1">{r.value}</Typography>
              </Box>
            )),
        },
      }),
      (signup: SignupInterface) => ({
        type: ItemType.text,
        key: 'address',
        hideItem: !signup.organization,
        props: {
          label: t('users:attributes.address'),
          placeholder: t('users:attributes.address'),
        },
      }),
    ],
    [t, requestOrganization],
  )
  const accountItems: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'password',
        required: true,
        rules: [YupUtils.FieldValidationType.password],
        props: {
          label: t('users:attributes.password'),
          placeholder: t('users:attributes.password'),
          type: 'password',
          endAdornment: (
            <Tooltip title={t('errors:password')}>
              <HelpOutline color="error" sx={{ marginTop: '8px' }} />
            </Tooltip>
          ),
        },
      },
      {
        type: ItemType.text,
        key: 'confirmPassword',
        required: true,
        rules: [
          (signup: SignupInterface) =>
            signup.password !== signup.confirmPassword
              ? t('errors:passwordsShouldMatch')
              : undefined,
        ],
        props: {
          label: t('users:attributes.confirmPassword'),
          placeholder: t('users:attributes.confirmPassword'),
          type: 'password',
        },
      },
      {
        type: ItemType.checkbox,
        key: 'canUseMyData',
        required: true,
        props: {
          label: t('sessions:components.formSignup.account.canUseMyData'),
        },
      },
      {
        type: ItemType.checkbox,
        key: 'receiveEmails',
        props: {
          children: (
            <Box display="flex" sx={{ transform: 'translate(0,-4px)' }}>
              <InputLabel sx={{ marginTop: '4px' }}>
                {t('sessions:components.formSignup.account.receiveEmails')}
              </InputLabel>
              <Tooltip title={t('sessions:components.formSignup.account.receiveEmailsTooltip')}>
                <HelpOutline color="primary" sx={{ marginLeft: '8px' }} />
              </Tooltip>
            </Box>
          ),
        },
      },
      (signup: SignupInterface) => ({
        type: ItemType.checkbox,
        key: 'rgpd',
        required: true,
        props: {
          children: (
            <>
              <Typography color={!signup.rgpd ? 'error' : undefined}>
                {t('sessions:components.formSignup.account.RGPD1')}
                <Box
                  component="span"
                  style={{ textDecoration: 'underline' }}
                  onClick={(evt) => {
                    evt.stopPropagation()
                    evt.preventDefault()
                    setModal('cgu')
                  }}>
                  {t('sessions:components.formSignup.account.RGPD2')}
                </Box>
                {t('sessions:components.formSignup.account.RGPD3')}
                <Box
                  component="span"
                  style={{ textDecoration: 'underline' }}
                  onClick={(evt) => {
                    evt.stopPropagation()
                    evt.preventDefault()
                    setModal('rgpd')
                  }}>
                  {t('sessions:components.formSignup.account.RGPD4')}
                </Box>
                {t('sessions:components.formSignup.account.RGPD5')}
              </Typography>
            </>
          ),
        },
      }),
    ],
    [t],
  )
  const steps: { title: string; items: FormItem[] }[] = useMemo(
    () => [
      {
        title: t('sessions:components.formSignup.informations.title'),
        items: informationsItems,
      },
      {
        title: t('sessions:components.formSignup.organization.title'),
        items: organizationItems,
      },
      {
        title: t('sessions:components.formSignup.account.title'),
        items: accountItems,
      },
    ],
    [informationsItems, organizationItems, accountItems, t],
  )

  if (showSuccess) {
    const success = (
      <Box px="50px" pb="40px" pt="30px">
        <Box display="flex" flexDirection="column" alignItems="center" mb="20px">
          <SuccessTitle variant="h2">
            {t('sessions:components.formSignup.success.title')}
          </SuccessTitle>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mb="30px">
          <SuccessDescription>
            {t('sessions:components.formSignup.success.description')}
          </SuccessDescription>
        </Box>
        <DialogActions>
          <Box width="270px">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                try {
                  onSuccess?.()
                  onClose?.()
                } catch (err: any) {
                  logIfDev(err)
                }
              }}>
              {t('global:actions.gotIt')}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    )
    return (
      <>
        {!!onClose && (
          <Modal onClose={onClose} maxWidth="md" hideAction>
            {success}
          </Modal>
        )}
        {!onClose && success}
      </>
    )
  }

  const formProps = {
    value: value,
    onSubmit: sessionService.signup,
    submitLabel: t('sessions:actions.signup'),
    onSuccess: setShowSuccess.bind(null, true),
    steps: steps,
    setValue: setValue,
  }
  return (
    <>
      {loading && <LoaderOverlay />}
      {!!onClose && (
        <ModalForm
          {...formProps}
          title={t('sessions:components.formSignup.modalTitle')}
          onClose={onClose}
          keepOpen
          maxWidth="md"
        />
      )}
      {!onClose && <Form {...formProps} />}
      {modal === 'addOrganization' && (
        <ModalFormOrganization
          isRegister
          title={t('sessions:components.formSignup.addOrganization')}
          submitLabel={t('global:actions.add')}
          onClose={() => setModal('')}
          onSubmit={setOrganization}
        />
      )}
      {/* TODO cgu & rgpd content... */}
      {modal && modal !== 'addOrganization' && (
        <Modal onClose={() => setModal('')} maxWidth="xl">
          <>
            {modal === 'cgu' && <CGUContent />}
            {modal === 'rgpd' && <RGPDContent />}
          </>
        </Modal>
      )}
    </>
  )
}

export default FormSignup
