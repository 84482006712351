import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserList, { UserListProps } from '../user/List.user'

import useRoute from '../../hooks/useRoute.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'

import { sessionService } from '../../store/session'
import { usersService } from '../../store/users'
import { organizationsService } from '../../store/organizations'

import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { Route } from '../../models/commons.models'
import { FilterItem, Filter, ItemType } from '../../models/props.models'
import { User, UserStatus, Profession, UserType } from '../../models/users.models'
import LoaderOverlay from '../layout/LoaderOverlay.layout'

import { ListRef } from '../common/List.common'

interface PageUserListProps
  extends Omit<
    UserListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
  > {
  organization?: string
}
const PageUserList: React.FC<PageUserListProps> = (props) => {
  const { organization, ...listProps } = props
  const { goTo } = useRoute()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const listRef = useRef<ListRef | null>(null)
  const show = useSnackbar()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: 'user' })
  }, [])

  const parseFilter = useCallback(
    (filters: any) => ({
      ...filters,
      organization: organization || filters?.organization?.value,
      ...(!showStatus && { status: undefined }),
    }),
    [organization, showStatus],
  )

  const getUsers = useCallback(
    async (filters: any) => {
      return await usersService.getUsers(parseFilter(filters))
    },
    [parseFilter],
  )

  const exportCsv = useCallback(async () => {
    setLoading(true)
    try {
      await usersService.getCsv(parseFilter(listRef.current?.filter))
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show, parseFilter])

  const filter = useMemo<Filter>(
    () => ({
      items: [
        ...((showStatus
          ? [
              {
                type: ItemType.multiSelect,
                key: 'status',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  placeholder: t('users:components.userlistPage.statusFilter'),
                  multiSelectedLabel: (count: number) =>
                    t('global:inputs.selectedOptions', { count }),
                  items: createOptionsFromEnum(UserStatus, 'users:status').filter(
                    (option) => option.value !== UserStatus.deleted,
                  ),
                },
              },
            ]
          : []) as FilterItem[]),
        ...((organization
          ? []
          : [
              {
                type: ItemType.asyncSelect,
                key: 'organization',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  placeholder: t('users:components.userlistPage.organizationFilter'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) =>
                    organizationsService.getOrganizationOptions(search),
                },
              },
            ]) as FilterItem[]),
        {
          type: ItemType.multiSelect,
          key: 'types',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('users:components.userlistPage.typeFilter'),
            multiSelectedLabel: (count: number) => t('global:inputs.selectedOptions', { count }),
            items: createOptionsFromEnum(UserType, 'users:types'),
          },
        },
        {
          type: ItemType.multiSelect,
          key: 'professions',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('users:components.userlistPage.professionsFilter'),
            multiSelectedLabel: (count: number) => t('global:inputs.selectedOptions', { count }),
            items: createOptionsFromEnum(Profession, 'users:professions'),
          },
        },
        {
          type: ItemType.search,
          key: 'search',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('users:components.userlistPage.searchFilter'),
          },
        },
        {
          type: ItemType.button,
          key: 'export',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            fullWidth: true,
            onClick: exportCsv,
            children: t('global:actions.export'),
          },
        },
      ],
    }),
    [t, organization, exportCsv, showStatus],
  )

  return (
    <>
      {loading && <LoaderOverlay />}
      <UserList
        {...listProps}
        ref={listRef}
        isOrganizationPage={!!organization}
        filter={filter}
        onValueClick={(user: User) => goTo({ route: Route.user, userId: user._id })}
        getValues={getUsers}
      />
    </>
  )
}
export default PageUserList
